import React, { FC, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import FaqQuestion from 'components/FaqQuestion';
import './FaqAccordion.scss';

const FaqAccordion: FC<FaqContent.IFaqSection> = ({ title, faqItems }) => {
  const [active, setActive] = useState<number | null>(null);

  return (
    <Accordion className="dt-accordion-custom">
      <h2 className="dt-faq-accordion__title">{title}</h2>
      {faqItems.map(({ properties }, index) => {
        return (
          <FaqQuestion
            key={properties.question}
            {...properties}
            active={active}
            setActive={setActive}
            index={index}
          />
        );
      })}
    </Accordion>
  );
};

export default FaqAccordion;
