import React, { FC } from 'react';

import ProductCarousel from 'components/common/ProductCarousel';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import { CarouselMasks } from 'components/common/ProductCarousel/models';
import { IProductInfoGallery } from './models';

import './ProductInfoGallery.scss';

const getCurrentImage = (image, alt) =>
  image ? (
    <button
      type="button"
      className="dt-product-info-gallery__item"
      key={image.childImageSharp.fluid.src}
    >
      <UmbracoImage
        alt={alt}
        image={{ ...image.childImageSharp, fallbackUrl: image.childImageSharp?.fluid?.src }}
      />
    </button>
  ) : null;

const ProductInfoGallery: FC<IProductInfoGallery> = ({ imgSet, alt, backGroundImage }) => {
  return (
    <div className="dt-product-info-gallery">
      <UmbracoImage alt={alt} image={backGroundImage} className="dt-umbraco-image__bg" />
      {imgSet.length > 1 ? (
        <ProductCarousel type={CarouselMasks.singleElement} isBtnGroup>
          {imgSet.map((image) => getCurrentImage(image, alt))}
        </ProductCarousel>
      ) : (
        <div className="dt-carousel">{getCurrentImage(imgSet[0], alt)}</div>
      )}
    </div>
  );
};

export default ProductInfoGallery;
