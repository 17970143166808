import React, { FC } from 'react';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { useId } from 'react-id-generator';

import IconCustom from 'components/common/IconCustom';
import { IDropdownCustom } from './models';
import BuyNowItem from './BuyNowItem';

import './ProductBuyNowDropdown.scss';

const ProductBuyNowDropdown: FC<IDropdownCustom> = ({
  buyNow,
  customFields,
  retailerClickHandler,
}) => {
  const [dropId] = useId();
  const id = `dt-dropdown-custom-${dropId}`;

  return (
    <Dropdown className="dt-dropdown-custom">
      <Dropdown.Toggle id={id} variant="primary" size="lg">
        {customFields && customFields.buttonBuyNow ? (
          <span>{customFields.buttonBuyNow}</span>
        ) : null}
        <IconCustom icon="next_arrow" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dt-dropdown-custom__popup">
        <div className="dt-dropdown-custom__buy-now">
          {customFields && customFields.orderNowFrom ? (
            <strong className="dt-dropdown-custom__title">{customFields.orderNowFrom}</strong>
          ) : null}
          <ul className="dt-dropdown-custom__logos">
            {buyNow.map((item) => (
              <BuyNowItem
                key={`${item.properties.shopName}`}
                {...item}
                retailerClickHandler={retailerClickHandler}
              />
            ))}
          </ul>
          <Dropdown.Item className="dt-dropdown-custom__close-btn">
            {customFields && customFields.buttonCancel ? (
              <Button variant="outline-primary">{customFields.buttonCancel}</Button>
            ) : null}
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProductBuyNowDropdown;
