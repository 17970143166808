import React, { FC } from 'react';

import UmbracoImage from 'components/common/Image/UmbracoImage';
import VariationsDropdown from 'components/DropdownVariations';
import ProductInfoGallery from 'components/ProductInfoGallery';
import BreadCrumbs from 'components/common/BreadCrumbs';
import ProductBuyNowDropdown from 'components/ProductBuyNowDropdown';
import DiscountButtonCol from 'components/common/DiscountButtonCol';
import NewsletterButtonCol from 'components/common/NewsletterButtonCol';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import isEmpty from 'utils/functions';
import DropdownProductReviews from 'components/DropdownProductReviews';

import { IProductInfo } from './models';
import './ProductInfo.scss';

const ProductInfo: FC<IProductInfo> = ({
  title,
  breadcrumbs,
  text,
  backGroundImage,
  imgSet,
  alt,
  type,
  buyNow,
  articleImage,
  scent,
  size,
  sizeScentFriendlyProducts,
  productColor,
  newsletterBanner,
  enableSignupButtonInProductPage,
  discountBanner,
  isLoggingProductSelection,
  newsletterSignUp,
  discountButton,
  showBV,
  id,
  customFields,
  hiddenImage,
  retailerClickHandler,
}) => {
  return (
    <div className="dt-product-info clearfix">
      {type === 'article' && articleImage && !hiddenImage && (
        <div className="dt-product-info__image-article">
          <UmbracoImage image={articleImage} alt={alt} lazyLoad={false} />
        </div>
      )}
      {breadcrumbs ? <BreadCrumbs data={breadcrumbs} /> : null}
      <h1 itemProp="name" className="title">
        {title}
      </h1>
      <DangerouslySetInnerHtml html={text} />
      {showBV ? (
        <div className="BV-summary-wrapper">
          <div data-bv-show="rating_summary" data-bv-product-id={id} />
        </div>
      ) : null}
      {type === 'product' && (
        <>
          {imgSet ? (
            <ProductInfoGallery imgSet={imgSet} alt={alt} backGroundImage={backGroundImage} />
          ) : null}
          {scent && size && sizeScentFriendlyProducts?.length ? (
            <VariationsDropdown
              scent={scent}
              sizeScentFriendlyProducts={sizeScentFriendlyProducts}
              size={size}
              productColor={productColor}
              isLoggingProductSelection={isLoggingProductSelection}
            />
          ) : null}
          <div className="dt-product-info__banner-btn-holder">
            {buyNow && !isEmpty(buyNow) && (
              <ProductBuyNowDropdown
                buyNow={buyNow}
                customFields={customFields}
                retailerClickHandler={retailerClickHandler}
              />
            )}
            {showBV ? <DropdownProductReviews id={id} customFields={customFields} /> : null}
            {Number(newsletterSignUp) && Number(enableSignupButtonInProductPage) ? (
              <NewsletterButtonCol newsletterBanner={newsletterBanner} />
            ) : null}
            {Number(discountButton) && discountBanner ? (
              <DiscountButtonCol discountBanner={discountBanner} />
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default ProductInfo;
