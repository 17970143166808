import React, { FC } from 'react';
import classNames from 'classnames';

import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import IconCustom from 'components/common/IconCustom';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import { IFaqQuestion } from './models';

const FaqQuestion: FC<IFaqQuestion> = ({ question, answer, index, active, setActive }) => {
  const questionClass = classNames({ 'card-header__is-active': active === index });

  const handleToggleClick = () => {
    setActive(active !== index ? index : null);
  };

  return (
    <Card>
      <Accordion.Toggle
        as={Card.Header}
        eventKey={index.toString()}
        className={questionClass}
        onClick={handleToggleClick}
      >
        <button type="button" className="accordion-opener">
          {question}
          <IconCustom icon="plus_icon" />
        </button>
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={index.toString()}>
        <Card.Body>
          <DangerouslySetInnerHtml html={answer} />
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default FaqQuestion;
