import React, { FC } from 'react';

import BreadCrumbsItem from './BreadCrumbsItem';
import { IBreadCrumbs } from './models';
import './BreadCrumbs.scss';

const BreadCrumbs: FC<IBreadCrumbs> = ({ data }) => (
  <ul className="dt-breadcrumbs">
    {data.map((item) => (
      <BreadCrumbsItem key={item.name} {...item} />
    ))}
  </ul>
);

export default BreadCrumbs;
