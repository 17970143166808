import React, { FC } from 'react';

import DropdownVariationsItem from './DropdownVariationsItem';
import { IDropdownVariation } from './models';
import './DropdownVariations.scss';

const byNameReducer = (accumulator, current) => {
  accumulator[current.name] = {
    ...accumulator[current.name],
    ...current,
  };

  return accumulator;
};

const formatVariants = (variants, conditions, key) => {
  Object.keys(variants).forEach((variant) => {
    const foundProductVariant = conditions.find(
      (product) => product[key] === variants[variant].name
    );

    if (foundProductVariant) {
      variants[variant].url = foundProductVariant.link;
    }
  });
};

const VariationsDropdown: FC<IDropdownVariation> = ({
  sizeScentFriendlyProducts,
  size,
  scent,
  productColor,
  isLoggingProductSelection,
}) => {
  const sameSizeDifferentScents = sizeScentFriendlyProducts.filter(
    (product) => product.size === size && product.scent !== scent
  );
  const sizeVariants = sizeScentFriendlyProducts
    .filter((product) => product.scent === scent && product.size !== size)
    .map((product) => ({
      name: product.size,
      url: product.link,
    }))
    .reduce(byNameReducer, {});
  const scentVariants = sizeScentFriendlyProducts
    .map((product) => ({
      name: product.scent,
      url: product.link,
    }))
    .filter((product) => product.name !== scent)
    .reduce(byNameReducer, {});

  formatVariants(scentVariants, sameSizeDifferentScents, 'scent');

  return (
    <div className="dt-variation-dropdown">
      <DropdownVariationsItem
        selected={size}
        type="size"
        items={Object.values(sizeVariants)}
        isLoggingProductSelection={isLoggingProductSelection}
      />
      <DropdownVariationsItem
        selected={scent}
        type="fragrance"
        productColor={productColor}
        items={Object.values(scentVariants)}
        isLoggingProductSelection={isLoggingProductSelection}
      />
    </div>
  );
};

export default VariationsDropdown;
