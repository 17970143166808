import React, { FC } from 'react';
import { Link } from 'gatsby';

import { IBreadCrumbsItem } from './models';

const BreadCrumbsItem: FC<IBreadCrumbsItem> = ({ link, name }) => (
  <li>
    <Link to={link}>{name}</Link>
  </li>
);

export default BreadCrumbsItem;
