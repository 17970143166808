import React, { FC } from 'react';

import './DiscountButtonCol.scss';

import IconCustom from 'components/common/IconCustom';
import extractUrlFromMultiPicker from 'utils/urlMultiPicker';
import { TDiscountButtonComponentProps } from './models';

const DiscountButtonCol: FC<TDiscountButtonComponentProps> = ({ bare, discountBanner }) => {
  const { regularTitle, titleText, footerButtonTitle, buttonText, buttonURL } = discountBanner;

  if (bare) {
    return (
      <div className="dt-discount-button-col">
        <a
          href={extractUrlFromMultiPicker(buttonURL)}
          className="dt-discount-button-col__btn btn btn-outline-primary"
        >
          <span>{footerButtonTitle}</span>
          <IconCustom icon="arrow_shops" />
        </a>
      </div>
    );
  }

  return (
    <div className="dt-discount-button-col">
      <strong className="dt-discount-button-col__title">{regularTitle}</strong>
      <a
        href={extractUrlFromMultiPicker(buttonURL)}
        className="dt-discount-button-col__btn btn btn-outline-primary"
      >
        <span>
          <strong>{buttonText}</strong>
          {titleText}
        </span>
        <IconCustom icon="arrow_shops" />
      </a>
    </div>
  );
};

export default DiscountButtonCol;
