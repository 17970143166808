import React, { FC, useCallback } from 'react';
import classNames from 'classnames';

import getDropDownItem from 'utils/dropDownControl';
import extractUrlFromMultiPicker from 'utils/urlMultiPicker';

import './BuyNowItem.scss';

const BuyNowItem: FC<ProductTypes.TBuyNow> = ({ properties, retailerClickHandler }) => {
  const { link, shopName, extraClass, name } = properties;

  const handleRetailerClick = useCallback(() => {
    if (typeof retailerClickHandler !== 'function') return;
    retailerClickHandler(name || shopName);
  }, []);

  return (
    <li className="dt-dropdown-custom__logos-item">
      <a
        className={classNames('dt-dropdown-custom__logos-item__link', {
          [`${extraClass}`]: extraClass,
        })}
        href={extractUrlFromMultiPicker(link)}
        target="_blank"
        rel="noreferrer noopener"
        onClick={handleRetailerClick}
      >
        <img
          className="dt-dropdown-custom__logos-item__img"
          src={`/images/${getDropDownItem(shopName)}`}
          alt={getDropDownItem(shopName)}
        />
      </a>
    </li>
  );
};

export default BuyNowItem;
