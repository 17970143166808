import React, { FC } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useId } from 'react-id-generator';
import Button from 'react-bootstrap/Button';
import IconCustom from 'components/common/IconCustom';

import { IDropdownProductReviewsProps } from './models';
import './DropdownProductReviews.scss';

const DropdownProductReviews: FC<IDropdownProductReviewsProps> = ({ id, customFields }) => {
  const [dropId] = useId();
  const dropDownId = `dt-dropdown-categories-${dropId}`;

  return (
    <Dropdown className="dt-dropdown-custom dt-dropdown-product-reviews">
      <Dropdown.Toggle id={dropDownId} variant="primary" size="lg">
        <span>SEE REVIEWS</span>
        <IconCustom icon="next_arrow" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="dt-dropdown-custom__reviews">
          <div data-bv-show="reviews" data-bv-product-id={id} />
          <div data-bv-show="questions" data-bv-product-id={id} />
          <Dropdown.Item className="dt-dropdown-custom__close-btn">
            <Button variant="outline-primary">{customFields.buttonCancel}</Button>
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownProductReviews;
