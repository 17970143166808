import React, { FC } from 'react';
import { navigate } from 'gatsby';

import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import './DropdownVariations.scss';
import { IDropdownVariationItem } from './models';

const VariationsDropdownItem: FC<IDropdownVariationItem> = ({
  items,
  selected,
  productColor,
  isLoggingProductSelection,
  type,
}) => {
  const tagsList = items.map((item) => {
    const handleSelect = (_, event) => {
      event.preventDefault();
      if (isLoggingProductSelection && window?.dataLayer) {
        window.dataLayer.push({ event: `${type}Selected`, [type]: item.name });
      }
      navigate(item.url);
    };

    return (
      <Dropdown.Item key={item.name} onSelect={handleSelect}>
        {item.name}
      </Dropdown.Item>
    );
  });

  return (
    <DropdownButton
      key={tagsList.length}
      variant="secondary"
      className={productColor?.toLowerCase()}
      title={selected}
      size="lg"
      disabled={!tagsList || !tagsList.length}
    >
      {tagsList}
    </DropdownButton>
  );
};

export default VariationsDropdownItem;
